<template>
    <div class="article">
        <!--  -->
        <div class="head padd20 borbut20">
            <!-- 
            <div class="zhong">
                <el-input v-model="user_name" placeholder="请输入姓名"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div> -->
            <div></div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">作者列表</div>
                <el-button type="primary" id="AddSystem" @click="addIntegral($event)" class="yinyinga">新增作者</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="authors_name" label="作者名称">
                    <!-- <template slot-scope="scope">
                        {{    Base64.decode(scope.row.authors_name)}}
                    </template> -->
                </el-table-column>
                <el-table-column prop="authors_head" label="头像">
                    <template slot-scope="scope">
                        <img :src="scope.row.authors_head" alt="">
                    </template>
                </el-table-column>

                <el-table-column prop="authors_desc" label="作者介绍">
                </el-table-column>
                <el-table-column prop="created_at" label="添加时间">
                </el-table-column>

                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.code" @change="text(scope.row)" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">查看详情</el-button>
                        <!-- <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm> -->
                        <!-- <el-button v-if="scope.row.is_del ==1" type="success" class="bianji" size="mini" plain>正常</el-button> -->
                        <!-- <el-button v-if="scope.row.is_del ==2" type="info" class="bianji" size="mini" plain>已删除</el-button> -->
                        <!-- <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">状态</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <Addarticle ref="Addarticle"></Addarticle>
            <EditArticle ref="EditArticle"></EditArticle>
        </div>
    </div>
</template>

<script>
import Addarticle from "./Addarticle.vue";
import EditArticle from "./EditArticle.vue";
export default {
    data() {
        return {
            user_name: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            page: "1",
            activeName: "",
        };
    },
    mounted() {
        this.getSysMessageTemp();
    },
    components: {
        Addarticle,
        EditArticle,
    },
    methods: {
        text(data) {
            var param = {
                id: data.id
            };
            if (data.is_del == 1) {
                param.is_del = 2;
            } else {
                param.is_del = 1;
            }
            this.$service.post(
                this.$api.setAuthorsStatus,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.getSysMessageTemp();
                    }
                }
            );
        },
        //设置状态
        star(val, row) {
            var param = {
                status: val,
                id: row.id,
            };
            this.$service.post(
                this.$api.setIntegralUserStatus,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.getSysMessageTemp();
                    }
                }
            );
        },
        //新增模板
        addIntegral() {
            // console.log( this.$refs.Addarticle.onyunyingtan())
            this.$refs.Addarticle.onyunyingtan();
        },
        //删除
        onEnter(row) {
            var param = {
                id: row.id,
            };
            this.$service.post(this.$api.delIntegralUser, param, (res) => {
                if (res.code == "200") {
                    this.getSysMessageTemp();
                }
            });
        },
        // 获取模板列表
        getSysMessageTemp() {
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getAuthorsList, param, (res) => {
                if (res.code == "200") {                                                                                                                                                                             
                    this.tableData = res.data.data;
                    res.data.data.forEach((v, K) => {
                        if (v.is_del == 1) {
                            this.tableData[K].code = true;
                        } else {
                            this.tableData[K].code = false;
                        }
                    });
                    this.lvtotal = res.data.total;
                }
            });
        },
        //搜索模板
        sousuo() {
            var param = {
                user_name: this.user_name,
            };
            // console.log(param);
            this.$service.get(this.$api.getAuthorsList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 编辑
        handleEdit(index, row, e) {
            this.$refs.EditArticle.onyunyingtan(row);
        },
        // 分页
        DialogCurrentChange(val) {
            this.tableData = [];
            this.page = val;
            this.getSysMessageTemp();
        },
    },
};
</script>

<style lang="scss" scoped>
.article {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .sansan {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //需要控制的文本行数
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.article {
    .el-input {
        width: 180px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
}
.testtooltip {
    width: 500px;
}
</style>