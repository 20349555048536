<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="新增作者" :visible.sync="yunyingtan" :closeOnClickModal='false' width="700px" @close="closeDialog()">
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            作者名称:
                        </div>
                        <el-input v-model="form.authors_name" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth" class="pitem">
                        <div class="kuai">
                            <span class="xing">* </span>
                            头像:
                        </div>
                        <el-upload class="upload-demo" ref='upload' :action="$api.uploadFile + '?token=' + token" list-type="picture-card" :on-success="onSuccessupload" :on-remove="handleRemoves">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width:120px">
                            <span class="xing">* </span>
                            作者介绍:
                        </div>
                        <el-input type="textarea" :rows="4" placeholder="请输入自我介绍" v-model="form.authors_desc">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            form: {
                //表单信息
                authors_desc: "", //自我介绍
                authors_name: "", //姓名
                authors_head: "", //头像
            },
            formLabelWidth: "140px",
            activeauthors_name: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            // dialogImageUrl: "",
            dialogVisible: false,
            page: 1,
            inputD: "",
            localismList: [], //配音师方言标签
            fileList: [],
        };
    },
    mounted() { },
    components: {
    },
    methods: {
        onyunyingtan() {
            this.yunyingtan = true;
        },
        onlocalismChange(val) {
            // this.localismList = val;
        },
        handleRemoves(file, fileList) {
            // this.dialogImageUrl = "";
        },
        closeDialog() {
            this.form.authors_desc = "";
            this.form.authors_name = "";
            this.form.authors_head = [];
            // this.dialogImageUrl = "";
            this.$refs.upload.clearFiles();
            // this.SourceList = "";
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao() {
            var param = this.form;
            if (param.authors_name == "") {
                this.$message({ message: "请输入作者姓名", type: "warning" });
                return;
            }
            if (param.authors_head == "") {
                this.$message({ message: "请上传作者头像", type: "warning" });
                return;
            }
            if (param.authors_desc == "") {
                this.$message({ message: "请输入作者自我介绍", type: "warning" });
                return;
            }
            param.id = "";
            console.log(param)
            this.$service.post(this.$api.createAuthors, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.yunyingtan = false;
                    this.form.authors_name = "";
                    this.form.authors_head = "";
                    this.form.authors_desc = "";
                    // this.dialogImageUrl = "";
                    this.$refs.upload.clearFiles();
                    this.$parent.getSysMessageTemp();
                }
            });
        },

        //上传文件移出
        onRemoveupload(file, fileList) {
            // console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //头像上传成功
        onSuccessupload(res, file, fileList) {
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.form.authors_head = res.data.file_url;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload(file, fileList) {
            // if (fileList.length > 1) {
            // 	fileList.splice(1, 1);
            // }
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },

        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 100px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>